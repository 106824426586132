/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import '@styles/react/libs/flatpickr/flatpickr.scss';

.required:after {
	content: " *";
	color: red;
}

.rdt_TableCol,
.rdt_TableCell {
    font-size: 14px !important;
}

.font-weight-800 {
	font-weight: 800;
}

.white-space-wrap {
	white-space: pre-wrap;
}

.app-refresh-box {
	position: absolute;
	top: 5%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999;
}

[contenteditable]:focus {
	outline: 0px solid transparent;
}

@keyframes lineInserted {
	from {
		height: 0;
	}

	to {
		height: 20px;
		/* cons: hardcoded height */
	}
}

div[contenteditable]>div {
	animation-duration: 300ms;
	animation-name: lineInserted;
	transition: height 300ms;
}

div[contenteditable] {
	max-height: 250px;
	overflow: auto;
	transition: all 300ms ease;
}

.DraftEditor-editorContainer>div[contenteditable] {
	max-height: 100px;
	overflow: auto;
	transition: all 300ms ease;
}

div.maxheight-400[contenteditable] {
	max-height: 400px;
	overflow: auto;
	padding: 8px;
	transition: all 300ms ease;
}

.mainEdit {
	box-sizing: border-box;
	flex: 1 1 auto;
	width: inherit;
	min-width: 0;
	min-height: 20px;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	outline: none;
	will-change: width;
	display: flex;
}

.chattools {

	color: var(--icon);
	will-change: width;
	visibility: visible;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	min-height: 52px;
}

.chat-app-form-with-reply {
	height: 125px !important;
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	flex-wrap: wrap;
}

.chatgroup {
	display: flex;
	align-items: flex-end;
	position: relative;
	z-index: 2;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	max-width: 100%;
	min-height: var(--height-pane-footer);
}

.chat-content {
	position: relative;
}

.reply-icon {
	position: absolute;
	display: none;
	right: 0px;
	top: 0px;
	width: 25px;
	background: radial-gradient(at top right, #7367f0 60%, #7367f000 80%);
	height: 25px;
	text-align: center;
	padding: 4px;
}

.dark-layout .chat-application .content-right .chat-app-window .active-chat .chat-left .chat-content .reply-icon-left {
	background: radial-gradient(at top right, #283046 60%, #28304600 80%);
	display: none;
}

.chat-unreadflg {
	position: absolute;
	right: 9px;
	top: 17px;
}

.chat-body {
	display: flow-root !important;
	overflow: initial !important;
}

.option-card {
	position: absolute;
	z-index: 9;
}

.chat-application .content-right .chat-app-window .active-chat .chat-left .chat-content .reply-icon-left {
	background: radial-gradient(at top right, #ffffff 60%, #ffffff00 80%);
	display: none;
}

.chat-content:hover .reply-icon,
.chat-application .content-right .chat-app-window .active-chat .chat-left .chat-content:hover .reply-icon-left,
.dark-layout .chat-application .content-right .chat-app-window .active-chat .chat-left .chat-content:hover .reply-icon-left {
	display: block;
}

div.maxheight-100[contenteditable] {
	max-height: 4em;
	min-height: 1.47em;
	-webkit-user-select: text;
	user-select: text;
	white-space: pre-wrap;
	word-break: break-word;
	padding: 9px 20px;
}

.text-overflow-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.popover {
	max-width: 450px;
}

.carousel-inne {
	height: 100% !important;
}

.carousal-window {
	height: 100%;
	max-height: 100%;
	overflow: auto;
}

.carousal-action {
	position: absolute;
	right: 0;
	top: 0;
}

@media print {
	body * {
		visibility: hidden;
	}

	.badge {
		visibility: hidden;
	}

	.invoice-container,
	.invoice-container * {
		visibility: visible;
	}

	.invoice-container {
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		box-shadow: none;
	}

	.d-flex {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		direction: ltr;
	}

	.invoice-padding {
		padding-left: 0rem;
		padding-right: 0rem;
	}
}

@page {
	size: inherit;
}

.rounded-4 {
	border-radius: 0.8rem !important;
	padding: 12px;
}

.chat-bubble,
.chat-bubble-reply {
	font-size: 13px;
	padding: 10px 15px;
	display: inline-block;
	position: relative;
	margin-bottom: 10px;
}

.chat-bubble:before {
	content: '';
	position: absolute;
	top: 13px;
	left: -12.4px;
	z-index: 998;
	border: 6px solid transparent;
	border-right-color: #ada7e32b;
}

.chat-bubble-reply:before {
	content: '';
	position: absolute;
	top: 13px;
	left: -12.4px;
	z-index: 998;
	border: 6px solid transparent;
	border-right-color: #ff9f431f;
}

/* File attachments */

.ui-attachment:hover .file-download {
	display: block;
}

.ui-attachment:hover .file-download {
	display: block;
}

.ui-attachment {
	background-color: #fff;
	border-radius: 5px !important;
	border: 1px solid #dddddd;
	height: 2.38rem;
	padding: 1.2rem 0.8rem 1.2rem 3.2rem;
	position: relative;
	background-clip: padding-box;
	box-sizing: border-box;
	margin: 0 0.8rem 0.8rem 0;
	float: left;
}

.ui-attachment--image:before {
	content: "IMG";
	background-color: #98d8f4;
}

.ui-attachment--pdf:before {
	content: "PDF";
	background-color: #cc1016;
}

.ui-attachment--docx:before {
	content: "DOC";
	background-color: #004182;
}

.ui-attachment--excel:before {
	content: "XLS";
	background-color: #057642;
}

.ui-attachment:before {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	color: #fff;
	text-indent: 0;
	width: 33px;
	height: 2.38rem;
	border: none;
	font-size: 12px;
	line-height: 2.38rem;
	text-align: center;
}

.modal-dsc {
	max-width: 80rem;
}

#compose-mail {
	.compose-mail-form-field {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.12rem $modal-header-padding-x;
		border-bottom: 1px solid $modal-footer-border-color;

		.select2-container--open {
			box-shadow: none;
		}

		.select2-selection--multiple,
		.form-control {
			border: 0;
			background-color: transparent;

			&:focus {
				box-shadow: none !important;
				background-color: transparent;
			}
		}

		.select2-selection--multiple {
			min-height: 42px !important;

			.select2-selection__rendered li .select2-search__field {
				margin-top: 10px;
			}
		}

		.select2-selection__choice__remove:before {
			top: 40%;
		}

		label {
			font-size: $font-size-base;
		}
	}

	// On compose form full screen
	.modal-fullscreen {
		.compose-form {
			height: 100%;
		}

		#message-editor {
			height: calc(100% - 240px);
		}

		.editor {
			height: calc(100% - 3rem);
		}
	}

	.editor {
		height: 250px;
	}

	.ql-container,
	.ql-toolbar {
		border: none;
		border-radius: 0;
		border-bottom: 1px solid $border-color;

		.ql-picker.ql-expanded .ql-picker-options {
			bottom: 100%;
			top: auto;
		}
	}

	.compose-footer-wrapper {
		padding: $modal-inner-padding;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.rdw-editor-wrapper .rdw-editor-main {
		min-height: 17rem;
	}
}

.DraftEditor-editorContainer>div[contenteditable] {
	max-height: 240px;
	overflow: auto;
	transition: all 300ms ease;
}

.fc .fc-button-primary {
	background-color: #fff !important;
	color: $primary !important;
	border-color: $primary !important;
	margin-left: 4px !important;
}


.parent-status-timeline {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	background: rgb(var(--zpBorderGrey), .02);
	padding: 20px 50px;
	align-items: center;
}

.parent-status-timeline>div {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	min-height: 37px;
	flex: 1;
	position: relative;
	animation: popout .3s forwards;
	animation-delay: .6s;
	width: 100%;
	justify-content: flex-end;
}

.flow-status-holder {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	flex-grow: 0;
	position: relative;
	height: 80px;
	flex: 1;
}

.straight-flow .flow-status-holder:after {
	content: '\25BA';
	justify-content: flex-end;
	border-top: 1px dashed #ced0d1;
	position: relative;
	width: calc(100% - 150px);
	height: 1px;
	margin-left: 10px;
	margin-right: 8px;
	transition: all .2s linear;
	animation: lineLoad .5s forwards linear;
	display: flex;
	align-items: center;
	color: #ced0d1;
	--width: 170px;
}

.straight-flow .flow-status-holder .flow-transiton-holder {
	display: flex;
	flex-direction: column;
	width: calc(100% - 130px);
	justify-content: center;
	position: absolute;
	opacity: 0;
	transition: .2s all linear;
	animation: ganttMinEft .3s forwards linear;
	animation-delay: .6s;
	right: 0;
	z-index: 1;
}

.straight-flow .flow-status-holder .flow-transiton-holder>.flow-transition-content {
	min-width: 0;
	white-space: nowrap;
	overflow: hidden;
	text-align: center !important;
	font-size: var(--fs11px);
	line-height: 38px;
	text-overflow: ellipsis;
	padding: 0 20px 0 10px;
	height: 40px;
}

.status-timeline.yettoupdate {
	border-top: 1px solid #ced0d1;
	background: #ced0d1;
	border-bottom: 1px solid #ced0d1;
	border-radius: 22px;
	max-width: 120px;
	text-align: right !important;
	margin-left: 10px;
	padding-left: 10px;
}

.image-repeat {
	width: 11% !important;
}

.fc-popover .image-repeat {
	width: 4% !important;
}

.fc-list-event-title .image-repeat {
	width: 2% !important;
}

@keyframes popout {
	from {
		transform: scale(0.8)
	}

	50% {
		transform: scale(1.1)
	}

	to {
		transform: scale(1)
	}
}

@keyframes ganttMinEft {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.billing-notification-bar {
	position: sticky;
	top: 0px;
	z-index: 999 !important;
}

.biling-navbar-top {
	top: 100px !important
}

.attachment-window {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
}

.file-manager-application .sidebar-file-manager {
	width: 260px;
	height: 100%;
	background-color: #fff;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
	transition: all 0.3s ease, background 0s;
}

.file-manager-application .sidebar-file-manager .sidebar-inner {
	height: inherit;
}

.file-manager-application .sidebar-file-manager .sidebar-inner .dropdown-actions {
	width: 100%;
	padding: 1.5rem 1.5rem 1.8rem;
}

.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-close-icon {
	position: absolute;
	top: 0.25rem;
	right: 0.25rem;
	font-size: 1.5rem;
	z-index: 5;
	cursor: pointer;
}

.file-manager-application .sidebar-file-manager .sidebar-inner .add-file-btn~.dropdown-menu {
	width: 85%;
}

.file-manager-application .sidebar-file-manager .sidebar-inner .add-file-btn~.dropdown-menu:before {
	display: none;
}

.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-list {
	position: relative;
	height: calc(100% - 85px);
}

.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-list .my-drive {
	padding: 0.58rem 0;
}

.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-list .my-drive .jstree-node .jstree-icon {
	background-position: 0.5rem;
	background-size: 1.3rem;
}

.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-list .my-drive .jstree-node .jstree-anchor>.jstree-themeicon {
	margin-right: 0.5rem;
}

.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-list .my-drive .jstree-node.jstree-closed>.jstree-icon {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%235e5873' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
}

.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-list .my-drive .jstree-node.jstree-open>.jstree-icon {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%235e5873' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-list .list-group-labels,
.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-list .storage-status {
	margin-top: 2.2rem;
}

.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-list .list-group-item {
	padding: 0.58rem 1.9rem;
	font-weight: 500;
	border: 0;
	border-radius: 0;
	border-left: 2px solid transparent;
}

.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-list .list-group-item:hover {
	z-index: 0 !important;
}

.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-list .list-group-item+.list-group-item.active {
	margin-top: 0;
}

.file-manager-application .sidebar-file-manager .sidebar-inner .sidebar-list .active {
	background-color: transparent;
	border-color: #7367f0;
	color: #7367f0;
}

.file-manager-application .sidebar-file-manager.show {
	transition: all 0.25s ease, background 0s !important;
	transform: translateX(0) !important;
	z-index: 10;
}

.file-manager-application .content-area-wrapper {
	border: 1px solid #ebe9f1;
	border-radius: 0.25rem;
}

.file-manager-application .content-area-wrapper .content-right .content-wrapper {
	padding: 0;
}

.file-manager-application .content-area-wrapper .file-manager-main-content {
	border-left: 1px solid #ebe9f1;
	height: inherit;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-header {
	padding: 0.4rem 1rem;
	border-bottom: 1px solid #ebe9f1;
	background-color: #fff;
	border-top-right-radius: 0.357rem;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-header .file-manager-toggler {
	cursor: pointer;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-header input {
	border-color: transparent;
	box-shadow: none;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-header .file-actions:not(.show) {
	display: none;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body {
	position: relative;
	padding: 1.5rem 1.5rem 0;
	height: calc(100% - 50px);
	background-color: #fff;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container {
	display: flex;
	flex-wrap: wrap;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container .file-manager-item {
	border: 1px solid #ebe9f1;
	margin-bottom: 1.6rem;
	box-shadow: none;
	margin-right: 1rem;
	transition: none;
	cursor: pointer;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container .file-manager-item .card-body {
	padding: 1rem;
	padding-bottom: 0.5rem;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container .file-manager-item .custom-control-input:not(:checked)~.custom-control-label:before {
	background-color: transparent;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container .file-manager-item .file-logo-wrapper {
	padding: 1rem;
	height: 7.5rem;
	background-color: #f8f8f8;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container .file-manager-item .file-logo-wrapper .feather-folder {
	stroke: #babfc7;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container .file-manager-item .dropdown-menu {
	transform: none;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container .file-manager-item .dropdown-menu:before {
	display: none;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container .file-manager-item .content-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: auto;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container .file-manager-item .file-date {
	flex-grow: 1;
	margin-bottom: 0;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container .file-manager-item.selected {
	border-color: #7367f0;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container .file-name {
	width: calc(100% - 26rem);
	min-height: 1rem;
	font-weight: 600;
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container .files-section-title {
	width: 100%;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view {
	flex-direction: column;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .files-section-title {
	display: none;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .files-header {
	display: flex;
	justify-content: space-between;
	margin-left: 7.2rem;
	margin-bottom: 1rem;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .files-header p {
	font-weight: 600;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .files-header .file-last-modified,
.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .files-header .file-item-size {
	margin-right: 3rem;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .file-manager-item {
	flex-direction: row;
	flex: 0 0 100%;
	align-items: center;
	max-width: 100%;
	margin-bottom: 0.75rem;
	margin-right: 0;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .file-manager-item .file-logo-wrapper {
	padding-right: 0;
	width: auto;
	height: auto;
	background-color: transparent !important;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .file-manager-item .file-logo-wrapper img {
	height: 1.5rem;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .file-manager-item .file-logo-wrapper .feather-folder,
.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .file-manager-item .file-logo-wrapper .feather-arrow-up {
	width: 19px;
	height: 18px;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .file-manager-item .file-logo-wrapper .dropdown {
	position: absolute;
	right: 1rem;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .file-manager-item .custom-checkbox {
	margin: 0 0.4rem 0 1.75rem;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .file-manager-item .file-accessed {
	display: none;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .file-manager-item .file-size {
	width: 5.71rem;
	flex-grow: 1;
	margin-bottom: 0;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container.list-view .folder.level-up .file-logo-wrapper {
	margin-left: 3.5rem;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container:not(.list-view) .files-header {
	display: none;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container:not(.list-view) .file-manager-item .content-wrapper {
	margin-bottom: 0rem;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container:not(.list-view) .file-manager-item .file-date {
	display: none;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container:not(.list-view) .file-manager-item .file-size {
	color: #b9b9c3;
	font-size: 85%;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container:not(.list-view) .file-manager-item:not(.selected):not(:hover) .custom-checkbox,
.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container:not(.list-view) .file-manager-item:not(.selected):not(:hover) .toggle-dropdown {
	opacity: 0;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container:not(.list-view) .file-manager-item .feather-folder {
	height: 32px;
	width: 35px;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container:not(.list-view) .file-manager-item.folder.level-up {
	display: none !important;
}

.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-body .view-container:not(.list-view) .file-manager-item .custom-checkbox {
	position: absolute;
	top: 1rem;
	left: 1rem;
}

@media screen and (max-width: 1200px) {
	.file-manager-application .content-right {
		width: 100%;
	}

	.file-manager-application .content-body {
		margin-left: 0 !important;
	}

	.file-manager-application .content-area-wrapper .file-manager-main-content {
		border-left: 0;
	}

	.file-manager-application .sidebar-left .sidebar {
		z-index: 10;
	}

	.file-manager-application .sidebar-left .sidebar .sidebar-file-manager {
		transform: translateX(-112%);
		transition: all 0.25s ease;
		position: absolute;
	}
}

@media (max-width: 767.98px) {
	.file-manager-application .view-container .file-manager-item {
		flex: 47%;
	}
}

// /*medium screen*/
// @media only screen and (min-width: 1200px) and (max-width : 1470px) {
// 	.horizontal-layout.navbar-floating:not(.blank-page) .app-content {
// 		padding: calc(6rem + 4.45rem* 2 + 1.3rem) 2rem 0 2rem !important;
// 	}
// }

.feedcard-left {
	position: fixed;
	left: 0;
}

.feedcard-right {
	position: fixed;
	right: 0;
}

@media (max-width: 575.98px) {

	.feedcard-left {
		position: initial;
		left: 0;
	}

	.feedcard-right {
		position: initial;
		right: 0;
	}

	.flex-50 {
		flex: 0 50%;
	}

	.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-header {
		padding-left: 0.5rem;
	}

	.file-manager-application .content-area-wrapper .file-manager-main-content .file-manager-content-header .file-actions .dropdown {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.file-manager-application .content-area-wrapper .view-container .file-manager-item {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.file-manager-application .content-area-wrapper .view-container.list-view .file-date,
	.file-manager-application .content-area-wrapper .view-container.list-view .file-last-modified,
	.file-manager-application .content-area-wrapper .view-container.list-view .file-size,
	.file-manager-application .content-area-wrapper .view-container.list-view .file-item-size {
		display: none !important;
	}
}

.file-manager-application .view-container .file-manager-item {
	flex: 0 0 47%;
	max-width: 50%;
}

@media (min-width: 576px) {
	.file-manager-application .view-container .file-manager-item {
		flex: 0 0 23%;
		max-width: 25%;
	}

	.flex-50 {
		flex: 0 50%;
	}
}

.border-white-3 {

	border: 3px solid #ffffff !important
}

.filter_popover {
	max-height: 15rem;
	height: 100%;
	overflow: auto;
	max-width: 20rem;
	width: 100%;
	overflow-x: hidden;
}

/* sticky Columns */
.sticky-col {
	position: -webkit-sticky;
	position: sticky !important;
	left: 0;
	z-index: 1;
}

/* SkeletonLoader.css */
.skeleton {
	background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
	background-size: 200% 100%;
	animation: loading 1.5s infinite;
	border-radius: 4px;
}

@keyframes loading {
	0% {
		background-position: -200% 0;
	}

	100% {
		background-position: 200% 0;
	}
}

.menu-notification-badge {
	position: absolute;
	top: 0;
	right: 0;
}

.themebg-withradius {
	background-color: #fff;
	border-radius: 0.357rem;
}

.themebg {
	background-color: #fff !important;
}

.carousel-indicators [data-bs-target] {
	box-sizing: content-box;
	flex: 0 1 auto;
	border-radius: 50%;
	width: 4px !important;
	height: 4px !important;
	background-color: $primary !important;
	text-indent: -999px;
	opacity: 1;
	transition: opacity 0.6s ease;
}

.carousel-indicators .active {
	opacity: 1;
	width: 19px !important;
	border-radius: 25%;
}

.height-130 {
	height: 130px !important;
}

.border-radius-1 {
	border-radius: 1%;
}

.border-radius-10 {
	border-radius: 10%;
}

.border-radius-25 {
	border-radius: 25%;
}

.tooltip-card {
	position: absolute;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 10px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
	z-index: 10;
	transition: opacity 0.3s ease;
}

.flatpickr-input~.form-control[readonly] {
	background-color: #fff !important;
}


.file-attachment {
	background-color: #fff;
	border-radius: 5px !important;
	border: 1px solid #dddddd;
	height: 2.38rem;
	padding: 1.2rem 0.8rem 1.2rem 3.2rem;
	position: relative;
	background-clip: padding-box;
	box-sizing: border-box;
	margin: 0 0.8rem 0.8rem 0;
	float: left;
}

.file-attachment--image:before {
	content: "";
	background-image: url('../images/icons/png.png');
	background-size: cover;
	background-repeat: no-repeat;

}

.file-attachment--pdf:before {
	content: "";
	background-image: url('../images/icons/pdf.png');
	background-size: cover;
	background-repeat: no-repeat;

}

.file-attachment--docx:before {
	content: "";
	background-image: url('../images/icons/docs.png');
	background-size: cover;
	background-repeat: no-repeat;

}

.file-attachment--excel:before {
	content: "";
	background-image: url('../images/icons/xls.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.file-attachment:before {
	display: block;
	position: absolute;
	top: 8px;
	left: 10px;
	width: 20px;
	height: 20px;
}

.header-navbar.navbar-shadow {
	box-shadow: 0px 2px 8px 0px #2F2B3D1F;
}


.apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-label,
.apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-value {
	font-size: 14px !important;
}

.max-height-450 {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 450px !important;
}

.comment-box {
	padding-bottom: 12px;
	display: flex;
	width: 100%;
	flex-direction: column;
	border: 1.7px solid #cac9ce;
	border-radius: 2px;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 400;

	.plus-button {
		align-items: center;
		width: 38px;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: larger;
		color: #7367F0;
		border-radius: 50%;
		cursor: pointer;
		background-color: #E3E1FC;
	}

	.post-button {
		padding: 10px 15px;
		font-size: 14px;
		font-weight: 500;
		color: #FFFFFF;
	}
}

.sidebar-footer {
	position: sticky;
	bottom: 0;
}

.footer-comment-box {
	position: sticky;
	bottom: 0;
	background-color: #fff;
	z-index: 99;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
	border-radius: 0.358rem !important;
}


/* -- sidebar -- */

.custom-sidebar {
	.modal-dialog.sidebar-lg {
		width: 40rem !important
	}
}

.rdt_TableCol:has(.filteredtd) {
	background-color: #7367f0 !important;
	background: rgba(115, 103, 240, 0.12) !important;
}

.col-md-14 {
	width: 14.2857142857%;
}

.select__menu {
	z-index: 999 !important;
}

.fileupload-container {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 999;
}

.custom-control-prev .carousel-control-prev-icon,
.custom-control-next .carousel-control-next-icon {
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 50%;
}

.custom-control-prev:hover .carousel-control-prev-icon,
.custom-control-next:hover .carousel-control-next-icon {
	background-color: rgba(0, 0, 0, 0.7);
}

.rdt_ExpanderRow {
	.rdt_TableHeadRow {
		display: none !important;
	}
}

.rdt_TableHeadRow {
	background-color: #f3f2f7 !important;
	color: #6e6b7b !important;
}

.rdt_TableRow {
	.text-body {
		color: rgba(0, 0, 0, 0.87) !important;
	}
}
.goto_item {
	display: none;	
	position: absolute;	
}


.file-path:hover > .goto_item {
	display: block;
}

svg.a-s-fa-Ha-pa {
	fill: #7367f0;
}

.PCg4Dd {
	fill: #fbbc04;
}

.ciqIod {
	fill: #4285f4;
}

.Hbae0e {
	fill: #ea4335;
}

.W9y3zc {
	fill: #34a853;
}